var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "breadcrumb" }, [
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleGoBack } },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "task-item" },
      [
        _c("div", { staticClass: "title-text fw600" }, [_vm._v("任务流程")]),
        _c("task-step", { attrs: { step: _vm.step, stepList: _vm.stepList } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "task-item" },
      [
        _c("div", { staticClass: "title-text fw600 mb26" }, [
          _vm._v("追缴对象"),
        ]),
        _c(
          "div",
          { staticClass: "recovery-object" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 10 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666" }, [_vm._v("汇总范围:")]),
                    _c(
                      "span",
                      [
                        _vm._v(" " + _vm._s(_vm.detailData.createdTime) + "  "),
                        _vm.detailData.recoverStartTimes == 0
                          ? [_vm._v(" 前已出场全部订单 ")]
                          : [
                              _vm._v(
                                " 前已出场达 " +
                                  _vm._s(_vm.detailData.recoverStartTimes) +
                                  " 小时的订单 "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 7 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666" }, [_vm._v("欠费金额:")]),
                    _vm._v(_vm._s(_vm.detailData.taskDebtMoney || "")),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 7 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666" }, [
                      _vm._v("欠费订单数:"),
                    ]),
                    _vm._v(_vm._s(_vm.detailData.taskDebtOrderNum || 0)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vehicle-detail" },
          [
            _c(
              "el-row",
              { staticClass: "mb16" },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("车辆:"),
                    ]),
                    _vm._v(_vm._s(_vm.detailData.carNum || 0) + "辆"),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("可触达车辆:"),
                    ]),
                    _vm._v(_vm._s(_vm.detailData.touchableCarNum || 0) + "辆 "),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("追缴订单总数:"),
                    ]),
                    _vm._v(
                      _vm._s(_vm.detailData.recoverCount || 0) + "个订单 "
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("可触达订单数:"),
                    ]),
                    _vm._v(
                      _vm._s(_vm.detailData.touchableCount || 0) + "个订单 "
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("追缴金额:"),
                    ]),
                    _vm._v(
                      _vm._s(_vm.detailData.recoverMoney / 100 || "0.00") +
                        "元 "
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("可触达金额:"),
                    ]),
                    _vm._v(
                      _vm._s(_vm.detailData.touchableMoney / 100 || "0.00") +
                        "元 "
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [
                    _c("span", { staticClass: "fc666 label-box" }, [
                      _vm._v("预估触达率:"),
                    ]),
                    _vm._v(_vm._s(_vm.detailData.touchablePercent || 0) + " "),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("task-table", {
          attrs: { detailType: _vm.detailType, id: _vm.id },
          on: { back: _vm.handleGetDetail },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "task-item" }, [
      _c("div", { staticClass: "title-text fw600 mb26" }, [_vm._v("基础信息")]),
      _c(
        "div",
        { staticClass: "recovery-object" },
        [
          _c(
            "el-row",
            { staticClass: "mb16" },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", [
                  _c("span", { staticClass: "fc666" }, [_vm._v("追缴编号:")]),
                  _vm._v(_vm._s(_vm.detailData.recoverCode)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", [
                  _c("span", { staticClass: "fc666" }, [_vm._v("追缴负责人:")]),
                  _vm._v(_vm._s(_vm.detailData.taskManagerName)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", [
                  _c("span", { staticClass: "fc666" }, [_vm._v("发起时间:")]),
                  _vm._v(_vm._s(_vm.detailData.createdTime)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mb16" },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", [
                  _c("span", { staticClass: "fc666" }, [_vm._v("审核人:")]),
                  _vm._v(_vm._s(_vm.detailData.reviewerName || "-")),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", [
                  _c("span", { staticClass: "fc666" }, [_vm._v("审核时间:")]),
                  _vm._v(_vm._s(_vm.detailData.revieTime || "-")),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", [
                  _c("span", { staticClass: "fc666" }, [_vm._v("追缴时间:")]),
                  _vm._v(_vm._s(_vm.detailData.recoverTime || "-")),
                ]),
              ]),
            ],
            1
          ),
          _vm.type == 1
            ? _c(
                "el-row",
                { staticClass: "mb16" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "fc666" }, [
                          _vm._v("追缴短信:"),
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              disabled: _vm.detailType != 1,
                            },
                            on: { change: _vm.setChange },
                            model: {
                              value: _vm.template,
                              callback: function ($$v) {
                                _vm.template = $$v
                              },
                              expression: "template",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.templateName,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                        _c("span", { staticClass: "fc999" }, [
                          _vm._v("短信模板取自运营营销-短信管理"),
                        ]),
                        _c("p", { staticClass: "fc999 mr4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("setSms")(_vm.detailData.content || "")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.type == 2
      ? _c("div", { staticClass: "task-item" }, [
          _c("div", { staticClass: "title-text fw600 mb26" }, [
            _vm._v("任务类型"),
          ]),
          _c(
            "div",
            { staticClass: "phone-box" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled: _vm.detailType == "2" || _vm.detailType == "3",
                  },
                  model: {
                    value: _vm.phoneType,
                    callback: function ($$v) {
                      _vm.phoneType = $$v
                    },
                    expression: "phoneType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("人工外呼")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("智能外呼")]),
                ],
                1
              ),
              _vm.phoneType == "1"
                ? _c("span", [
                    _vm._v(
                      "外呼模板：亲爱的 {【车牌号】} 车主，您好，目前您的停车产生欠费，请尽快通过{车主移动端名车}进行车辆缴费，谢谢合作。"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.detailType == "1" || _vm.detailType == "3"
      ? _c(
          "div",
          { staticClass: "btn-group" },
          [
            _vm.type == "1" && (_vm.detailType == "1" || _vm.detailType == "3")
              ? _c(
                  "el-button",
                  {
                    staticClass: "sms-btn mr20",
                    on: {
                      click: function ($event) {
                        _vm.smsDialogVisible = true
                      },
                    },
                  },
                  [_vm._v("短信预览")]
                )
              : _vm._e(),
            _vm.detailType == "3"
              ? [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr20",
                      attrs: { loading: _vm.submitLoading },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("2")
                        },
                      },
                    },
                    [_vm._v("驳回")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitLoading },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("1")
                        },
                      },
                    },
                    [_vm._v("通过")]
                  ),
                ]
              : _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.submitLoading },
                    on: {
                      click: function ($event) {
                        return _vm.handleSubmit("0")
                      },
                    },
                  },
                  [_vm._v("提交")]
                ),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "taskDialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "短信预览",
              visible: _vm.smsDialogVisible,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.smsDialogVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "sms-box" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("setSms")(_vm.detailData.content || "")) +
                  " "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }