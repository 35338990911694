var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "step-content" }, [
    _c(
      "div",
      { staticClass: "step-box" },
      _vm._l(_vm.stepList, function (item, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "step-item fl",
            class: _vm.stepList.length > 3 ? "select" : "",
          },
          [
            _c(
              "div",
              {
                staticClass: "step-item fl",
                class: {
                  "success-box": _vm.step > item.label,
                  "select-box": _vm.step == item.label,
                },
              },
              [
                item.label != 1
                  ? _c("el-divider", { staticClass: "fl" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.step == item.label ? item.lineText : "") +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "fl main" }, [
                  _vm.step > item.label
                    ? _c("div", { staticClass: "icon-box" }, [
                        _c("i", { staticClass: "el-icon-check" }),
                      ])
                    : _c("div", { staticClass: "icon-box" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                  _c("h3", [_vm._v(_vm._s(item.title))]),
                  _c("div", { staticClass: "detail-box" }, [
                    _c("p", { staticClass: "user" }, [
                      _vm._v(_vm._s(item.user)),
                    ]),
                    _c("p", { staticClass: "date-box" }, [
                      _vm._v(_vm._s(item.date)),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }